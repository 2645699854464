<script>
import { ref, computed } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'purchase-external-modal',
  components: {
    BaseMdl,
    BaseButton,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'giftcard',
      validator: (v) => /giftcard|voucher/.test(v),
    },
  },
  setup(props) {
    const loaded = ref(false)
    const iframeRef = ref(null)

    const title = computed(() => {
      switch (props.type) {
        case 'voucher':
          return 'View eVoucher'
        case 'giftcard':
        default:
          return 'View eGift Card'
      }
    })

    function print() {
      iframeRef.value.contentWindow.postMessage('print', '*')
    }

    return {
      loaded,
      print,
      iframeRef,
      title,
    }
  },
}
</script>

<template>
  <base-mdl
    :padding="false"
    width="sme"
    height="lg"
    fullscreen="mobile"
    :title="title"
  >
    <template #menu>
      <base-button icon="plain/printer" :disabled="!loaded" @click="print" />
    </template>

    <iframe
      ref="iframeRef"
      :src="url"
      class="h-full w-full"
      @load="loaded = true"
    />
  </base-mdl>
</template>
